/**
 * @name: 会员管理-会员信息管理接口文件
 * @author: Nobai
 * @date: 2024-05-13 11:37
 * @description： 会员管理-会员信息管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IMember, IMemberDropParams, IMemberParams } from "./types";

export const memberPageApi = (params: IMemberParams) => get<IPageRes<IMember[]>>("/admin/user/query", params)

export const memberExportApi = (params: IMemberParams) => get("/admin/user/export", params, 'blob')

export const memberCommunityPageApi = (params: any) => get('/admin/user/community/contribute/query', params)

export const memberCommunityExportApi = (params: any) => get('/admin/user/community/contribute/export', params, 'blob')

export const memberContributePageApi = (params: any) => get('/admin/user/grade/contribute/query', params)

export const memberContributeExportApi = (params: any) => get('/admin/user/grade/contribute/export', params, 'blob')

export const memberBalancePageApi = (params: any) => get('/admin/user/balance/query', params)

export const memberBalanceExportApi = (params: any) => get('/admin/user/balance/export', params, 'blob')

export const memberDropApi = (params: IMemberDropParams) => get<IMember[]>('/admin/user/getList', params)

export const memberDrop2Api = (params: any) => get<IMember[]>('/admin/city/operate/account/getUserList', params)
