
/**
 * @name: 城市运营管理-城市运营账号管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 城市运营管理-城市运营账号管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import moment from 'moment'
import {
  cityAccountPageApi,
  cityAccountCreateApi,
  cityAccountUpdateStatusApi,
  cityAccountEditNoteApi,
  cityAccountTimeApi,
  cityAccountExportApi
} from '@/apis/city/account'
import {
  ICityAccount,
  ICityAccountParams
} from '@/apis/city/account/types'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common"
import { IMember } from "@/apis/member/list/types"
import { memberDrop2Api } from "@/apis/member/list"

@Component({})
export default class cityAccount extends Vue {
  config = config
  moment = moment
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICityAccount[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICityAccountParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ICityAccount> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    addTitle: '添加',
    dialogWidth: '600px',
    column: [
      {
        "label": "城市运营账号表id",
        "prop": "id",
        "align": "left",
        "width": 180,
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "用户id",
        "prop": "userId",
        "align": "center",
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        label: '会员',
        prop: 'member',
        search: true,
        addHide: true,
        hide: true,
        placeholder: "昵称/手机号"
      },
      {
        label: "昵称",
        prop: 'nickName',
        align: 'center',
        addHide: true,
        width: 150,
        overHidden: true
      },
      {
        label: "手机号",
        prop: 'phone',
        align: 'center',
        addHide: true,
        slot: true
      },
      {
        "label": "身份类型",
        "prop": "type",
        "align": "center",
        "type": "select",
        "search": true,
        "dicData": [
          {
            "label": "城市合伙人",
            "value": 1
          },
          {
            "label": "社区合伙人",
            "value": 2
          }
        ],
        "rules": [
          {
            required: true,
            message: '请选择身份类型',
            trigger: 'blur'
          }
        ]
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "type": "select",
        "search": true,
        "slot": true,
        "width": 150,
        "addHide": true,
        "dicData": [
          {
            "label": "启用",
            "value": 1
          },
          {
            "label": "禁用",
            "value": 2
          }
        ]
      },
      {
        "label": "生效时间",
        "prop": "assertTime",
        "type": "daterange",
        "slot": true,
        "align": "center",
        "addSlot": true,
        "width": 300
      },
      {
        "label": "生效开始时间",
        "prop": "assertStartTime",
        "align": "center",
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "生效结束时间",
        "prop": "assertEndTime",
        "align": "center",
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true
      },
      {
        "label": "备注",
        "type": "textarea",
        "prop": "remarks",
        "align": "center",
        "maxlength": 100,
        "showWordLimit": true,
        overHidden: true
      },
      {
        "label": "经办时间",
        "prop": "addTime",
        "align": "center",
        "search": true,
        "addHide": true,
        "type": "daterange",
        "width": 150
      },
      {
        "label": "经办人",
        "prop": "addName",
        "align": "center",
        "addHide": true
      }
    ]
  }

  selectMemberPhone = ''

  selectMembers: IMember[] = []

  memberList: IMember[] = []

  loading = false

  remarkDialog = false

  assertTimeDialog = false

  selectId: string = ''

  remarkInput: string = ''

  assertTimes: string[] = []

  openAdd () {
    this.selectMembers = []
    this.selectMemberPhone = ''
    this.memberList = []
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
    setTimeout(() => {
      this.$set(this.modelForm, 'assertTime', [])
    }, 0)
  }

  switchStatus (id: string, status: number) {
    cityAccountUpdateStatusApi({id, status}).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  exportExcel () {
    const query: ICityAccountParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    cityAccountExportApi(query).then(e => {
      exportFile(e, '城市运营账号.xlsx')
    })
  }

  remoteMethod (query: string) {
    if (query !== '') {
      this.loading = true;
      memberDrop2Api({member: query, status: 1, type: this.modelForm.type}).then(e => {
        this.memberList = e.filter(item => !this.selectMembers.some(i => i.phone === item.phone)).map(item => {
          return {
            ...item,
            cusName: `${item.nickName} ${item.phone}`
          }
        })
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.memberList = [];
    }
  }

  handleAddMember () {
    if (!this.selectMemberPhone) {
      return this.$message.error('请选择会员')
    }
    const member = this.memberList.find(item => item.phone === this.selectMemberPhone)
    if (!member) {
      return this.$message.error('会员数据异常，请刷新页面重试')
    }
    this.selectMembers.push(member)
    this.selectMemberPhone = ''
    this.memberList = []
    this.loading = false
  }

  handleRemoveMember (index: number) {
    this.selectMembers.splice(index, 1)
  }

  rowSave (form: ICityAccount, done: Function, loading: Function) {

    const data: ICityAccount = deepCopy(form)
    if (data.assertTime && data.assertTime.length) {
      data.assertStartTime = data.assertTime[0]
      data.assertEndTime = data.assertTime[1]
    } else {
      data.assertStartTime = ''
      data.assertEndTime = ''
    }
    delete data.assertTime

    const arr: Partial<ICityAccount>[] = this.selectMembers.map((item) => {
      return {
        userId: item.id,
        type: data.type,
        remarks: data.remarks,
        assertStartTime: data.assertStartTime,
        assertEndTime: data.assertEndTime
      }
    })

    cityAccountCreateApi(arr).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true

    const query: ICityAccountParams = deepCopy(this.queryParam)

    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    cityAccountPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  openRemarkDialog (row: ICityAccount) {
    this.selectId = row.id
    this.remarkInput = row.remarks
    this.remarkDialog = true
  }

  remarkEnter (done: Function, loading: Function) {
    cityAccountEditNoteApi({
      id: this.selectId,
      remarks: this.remarkInput
    }).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  remarkClose () {
    this.remarkInput = ''
    this.selectId = ''
  }

  openAssertTimeDialog (row: ICityAccount) {
    this.selectId = row.id
    if (row.assertStartTime && row.assertEndTime) {
      this.assertTimes = [row.assertStartTime, row.assertEndTime]
    } else {
      this.assertTimes = []
    }
    this.assertTimeDialog = true
  }

  assertTimeEnter (done: Function, loading: Function) {
    const form: Partial<ICityAccount> = {}
    if (this.assertTimes && this.assertTimes.length) {
      form.assertStartTime = this.assertTimes[0]
      form.assertEndTime = this.assertTimes[1]
    } else {
      form.assertStartTime = ''
      form.assertEndTime = ''
    }
    form.id = this.selectId
    cityAccountTimeApi(form).then(e => {
      if (e) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  assertTimeClose () {
    this.assertTimes = []
    this.selectId = ''
  }

  created () {
    this.getList()
  }
}
