/**
 * @name: 城市运营管理-城市运营账号管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 城市运营管理-城市运营账号管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ICityAccount, ICityAccountParams } from "./types";

export const cityAccountPageApi = (params: ICityAccountParams) => get<IPageRes<ICityAccount[]>>('/admin/city/operate/account/query', params)

export const cityAccountCreateApi = (data: Partial<ICityAccount>[]) => postJ('/admin/city/operate/account/create', data)

export const cityAccountUpdateStatusApi = (data: Partial<ICityAccount>) => postJ('/admin/city/operate/account/updateStatus', data)

export const cityAccountEditNoteApi = (data: Partial<ICityAccount>) => postJ('/admin/city/operate/account/editNotes', data)

export const cityAccountTimeApi = (data: Partial<ICityAccount>) => postJ('/admin/city/operate/account/setEffectiveTime', data)

export const cityAccountExportApi = (params: ICityAccountParams) => get('/admin/city/operate/account/export', params, 'blob')
